/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.badge {
  display: inline-flex;
  border-radius: 36px;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0 6px;
  align-items: center;
}
.badge-variant--filled {
  color: black;
}
.badge-variant--filled.badge-color--notification {
  color: #eee;
}
.badge-variant--filled.badge-color--info {
  background: #137ffb;
}
.badge-variant--filled.badge-color--success {
  background: #00ff80;
}
.badge-variant--filled.badge-color--dangerous {
  background: #e86464;
}
.badge-variant--filled.badge-color--warning {
  background: #ffa200;
}
.badge-variant--filled.badge-color--common {
  background: #7bc944;
}
.badge-variant--filled.badge-color--rare {
  background: #40a8f4;
}
.badge-variant--filled.badge-color--epic {
  background: #d42ae6;
}
.badge-variant--filled.badge-color--legendary {
  background: #ffa727;
}
.badge-variant--filled.badge-color--notification {
  background: #cc0022;
}
.badge-variant--filled.badge-color--light-grey {
  background: #a5a5b4;
}
.badge-variant--filled.badge-color--electric-blue {
  background: #3845ff;
  color: white;
}
.badge-variant--filled.badge-color--orange {
  background: #fe751d;
  color: white;
}
.badge-variant--filled.badge-color--info {
  color: white;
}
.badge-variant--outlined.badge-color--info {
  color: #137ffb;
  border: 1px solid #137ffb;
}
.badge-variant--outlined.badge-color--success {
  color: #00ff80;
  border: 1px solid #00ff80;
}
.badge-variant--outlined.badge-color--dangerous {
  color: #e86464;
  border: 1px solid #e86464;
}
.badge-variant--outlined.badge-color--warning {
  color: #ffa200;
  border: 1px solid #ffa200;
}
.badge-variant--outlined.badge-color--common {
  color: #7bc944;
  border: 1px solid #7bc944;
}
.badge-variant--outlined.badge-color--rare {
  color: #40a8f4;
  border: 1px solid #40a8f4;
}
.badge-variant--outlined.badge-color--epic {
  color: #d42ae6;
  border: 1px solid #d42ae6;
}
.badge-variant--outlined.badge-color--legendary {
  color: #ffa727;
  border: 1px solid #ffa727;
}
.badge-variant--outlined.badge-color--notification {
  color: #cc0022;
  border: 1px solid #cc0022;
}
.badge-variant--outlined.badge-color--light-grey {
  color: #a5a5b4;
  border: 1px solid #a5a5b4;
}
.badge-variant--outlined.badge-color--electric-blue {
  color: #3845ff;
  border: 1px solid #3845ff;
}
.badge-variant--outlined.badge-color--orange {
  color: #fe751d;
  border: 1px solid #fe751d;
}