/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.explanation-panel {
  background: #22222a;
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
}
@media (min-width: 769px) {
  .explanation-panel__horizontal {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .explanation-panel__large-vertical {
    flex-direction: column;
  }
}
.explanation-panel__header {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 0;
  position: relative;
  margin-bottom: 18px;
  min-width: 240px;
  max-height: 150px;
  min-height: 96px;
  overflow: hidden;
}
.explanation-panel__header--winter-soldier {
  background: transparent linear-gradient(125deg, #751aff 0%, #61ffcd 100%) 0% 0% no-repeat padding-box;
}
.explanation-panel__header--candy {
  background: transparent linear-gradient(125deg, #ff3355 0%, #7419ff 100%) 0% 0% no-repeat padding-box;
}
.explanation-panel__header--mystic {
  background: transparent linear-gradient(125deg, #751aff 0%, #61ffcd 100%) 0% 0% no-repeat padding-box;
}
.explanation-panel__header--sunset {
  background: transparent linear-gradient(125deg, #ffc05c 0%, #f35 100%) 0% 0% no-repeat padding-box;
}
.explanation-panel__header--sunset-flat {
  background: transparent linear-gradient(180deg, #fa2566 0%, #ffcd1e 100%) 0% 0% no-repeat padding-box;
}
.explanation-panel__header--green {
  background: transparent linear-gradient(95.77deg, #61ffcd 0.15%, #338a6e 100.77%) 0 0 no-repeat padding-box;
}
@media (min-width: 769px) {
  .explanation-panel__header {
    margin-right: 36px;
  }
}
@media (min-width: 1600px) {
  .explanation-panel__header {
    margin-right: 0;
  }
}
.explanation-panel__header::after {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-position: bottom;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.explanation-panel__header__icon {
  color: #fff;
  width: 63px;
  height: 57px;
}
.explanation-panel__close {
  border-radius: 50%;
  position: absolute;
  right: 18px;
  top: 12px;
  height: 28px;
  width: 28px;
  font-size: 0.75rem;
}