/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.noscroll {
  overflow: hidden;
}

.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: auto;
}
.dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 30px;
  bottom: 0;
}
.dialog__wrapper {
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: auto;
  z-index: 600;
}
@supports (backdrop-filter: blur(5px)) {
  .dialog__wrapper--blur {
    background: rgba(14, 14, 17, 0.7);
    backdrop-filter: blur(10px);
  }
}
.dialog__panel {
  flex: 1;
}
.dialog__result {
  flex: 1;
  background: #41414d;
  padding: 24px;
  text-align: center;
  position: relative;
  border-radius: 12px;
}
@media (min-width: 64em) {
  .dialog__result {
    padding: 48px 24px 24px;
  }
}
@media (min-width: 41.87em) {
  .dialog__result {
    padding: 72px 48px 36px;
  }
}
.dialog__result__title {
  font-size: 1.125rem;
  margin-bottom: 12px;
}
@media (min-width: 41.87em) {
  .dialog__result__title {
    font-size: 20px;
  }
}
.dialog__result__title--success {
  color: #00ff81;
}
.dialog__result__title--warning {
  color: #ffa200;
}
.dialog__result__title--error {
  color: #e64759;
}
.dialog__result__footer {
  padding-top: 48px;
}
.dialog__simple {
  flex: 1;
  background: linear-gradient(174.37deg, #34343d 27.41%, #22222a 116.95%);
  border: 1px solid #353540;
  padding: 24px;
  position: relative;
  border-radius: 12px;
}
@media (min-width: 64em) {
  .dialog__simple {
    padding: 48px 24px 24px;
  }
}
@media (min-width: 41.87em) {
  .dialog__simple {
    padding: 48px 48px 36px;
  }
}
.dialog__simple__title {
  font-size: 1.125rem;
  margin-bottom: 12px;
}
.dialog__close {
  position: absolute;
  right: 12px;
  top: 0;
  border: 0 !important;
  z-index: 200;
}
.dialog__close:hover {
  color: #137ffb;
}
.dialog__close svg {
  font-size: 20px;
}
.dialog--small {
  max-width: 480px;
  width: 100%;
}
.dialog--normal {
  max-width: 720px;
  width: 100%;
}
.dialog--auth {
  max-width: 800px;
  width: 100%;
}
.dialog--large {
  max-width: 980px;
  width: 100%;
}
.dialog--xlarge {
  max-width: 1360px;
  width: 100%;
}
.dialog--opacity .dialog__overlay {
  opacity: 0.4;
}
.dialog--fullscreen {
  max-width: calc(100% - 24px);
  width: 100%;
}
.dialog--fullscreen > * {
  min-height: calc(100vh - 24px);
}
.dialog--fullOnSmall {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 41.87em) {
  .dialog--fullOnSmall > article {
    min-height: 100vh;
  }
}
.dialog--overflowH {
  overflow: hidden;
}
.dialog__background-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 12px 24px;
}
@media (min-width: 64em) {
  .dialog__background-content {
    padding: 48px;
  }
}
.dialog--noCentered {
  display: block;
  min-height: calc(var(--vh) * 100);
  max-width: 100%;
  width: 100%;
}

.dialog-image-column {
  position: relative;
  padding: 48px 24px 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 36px;
  background: #22222a;
  width: 100%;
}
@media (min-width: 41.87em) {
  .dialog-image-column {
    padding: 36px 24px;
    grid-template-columns: 0.5fr 0.5fr;
  }
}
.dialog-image-column__image {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0d0d10;
}
.dialog-image-column__image img {
  max-height: 160px;
}
@media (min-width: 41.87em) {
  .dialog-image-column__image img {
    max-height: none;
  }
}
.dialog-image-column__content, .dialog-image-column__body {
  display: flex;
  flex-direction: column;
}
.dialog-image-column__body {
  flex-grow: 1;
  justify-content: center;
}
.dialog-image-column__title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 24px;
}

.empty-dialog {
  flex: 1;
}
.empty-dialog--full-h {
  display: flex;
  flex-direction: column;
}

@supports (backdrop-filter: blur(1px)) {
  .dialog__wrapper--opacity {
    background: rgba(13, 13, 16, 0.85);
    backdrop-filter: blur(15px);
  }
}