/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.selected, .weird, .warning, .safe, .dangerous, .cta, .primary, .button {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  color: #cfd8dc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #cfd8dc;
  border-radius: 6px;
  background: transparent;
  padding: 9px 36px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.selected:hover, .weird:hover, .warning:hover, .safe:hover, .dangerous:hover, .cta:hover, .primary:hover, .button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.selected:focus, .weird:focus, .warning:focus, .safe:focus, .dangerous:focus, .cta:focus, .primary:focus, .button:focus {
  box-shadow: 0 0 2px 0 #60ffcc;
  outline: 0;
}
@media (min-width: 64em) {
  .selected + .button, .weird + .button, .warning + .button, .safe + .button, .dangerous + .button, .cta + .button, .primary + .button, .button + .button {
    margin-left: 12px;
  }
}

.button__loading-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  display: flex;
  align-items: center;
}
.button--sm {
  font-size: 0.75rem;
  padding: 6px 12px;
}
.button--sm .button__loading-icon {
  left: 9px;
}
.button--sm .button--icon__icon {
  font-size: 0.875rem;
}
.button--lg {
  font-size: 1rem;
  padding: 12px 48px;
}
.button--lg .button__loading-icon {
  left: 24px;
}
.button--xl {
  font-size: 1.125rem;
  padding: 15px 60px;
}
.button--xl .button__loading-icon {
  left: 36px;
}
.button--ghost {
  border-color: transparent;
}
.button--ghost.button--success {
  color: #00ff80;
}
.button--ghost.button--success:hover {
  color: #00cc66;
}
.button--ghost.button--dangerous {
  color: #e86464;
}
.button--ghost.button--dangerous:hover {
  color: #e13737;
}
.button--ghost.button--warning {
  color: #ffa200;
}
.button--ghost.button--warning:hover {
  color: #cc8200;
}
.button--ghost.button--light-grey {
  color: #a5a5b4;
}
.button--ghost.button--light-grey:hover {
  color: #89899d;
}
.button--ghost.button--checkout {
  color: #f0b342;
}
.button--ghost.button--checkout:hover {
  color: #eca013;
}
.button--ghost.button--white {
  color: white;
}
.button--ghost.button--white:hover {
  color: #e6e6e6;
}
.button--ghost.button--grey {
  color: #646473;
}
.button--ghost.button--grey:hover {
  color: #4c4c57;
}
.button--ghost.button--black-low {
  color: #42424d;
}
.button--ghost.button--black-low:hover {
  color: #2a2a32;
}
.button--ghost.button--info {
  color: #137ffb;
}
.button--ghost.button--info:hover {
  color: #0367d8;
}
.button--ghost.button--success-dark {
  color: #0f9151;
}
.button--ghost.button--success-dark:hover {
  color: #0a6337;
}
.button--ghost.button--charity-animal-darker {
  color: #20b13b;
}
.button--ghost.button--charity-animal-darker:hover {
  color: #19862d;
}
.button--filled {
  color: #22222a;
}
.button--filled.button--success {
  background: #00ff80;
  border-color: #00ff80;
}
.button--filled.button--success:hover {
  background: #00cc66;
  border-color: #00cc66;
}
.button--filled.button--dangerous {
  background: #e86464;
  border-color: #e86464;
}
.button--filled.button--dangerous:hover {
  background: #e13737;
  border-color: #e13737;
}
.button--filled.button--warning {
  background: #ffa200;
  border-color: #ffa200;
}
.button--filled.button--warning:hover {
  background: #cc8200;
  border-color: #cc8200;
}
.button--filled.button--light-grey {
  background: #a5a5b4;
  border-color: #a5a5b4;
}
.button--filled.button--light-grey:hover {
  background: #89899d;
  border-color: #89899d;
}
.button--filled.button--checkout {
  background: #f0b342;
  border-color: #f0b342;
}
.button--filled.button--checkout:hover {
  background: #eca013;
  border-color: #eca013;
}
.button--filled.button--white {
  background: white;
  border-color: white;
}
.button--filled.button--white:hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.button--filled.button--grey {
  background: #646473;
  color: #fefefe;
  border-color: #646473;
}
.button--filled.button--grey:hover {
  background: #4c4c57;
  border-color: #4c4c57;
}
.button--filled.button--black-low {
  background: #42424d;
  color: #fefefe;
  border-color: #42424d;
}
.button--filled.button--black-low:hover {
  background: #2a2a32;
  border-color: #2a2a32;
}
.button--filled.button--info {
  background: #137ffb;
  color: #fefefe;
  border-color: #137ffb;
}
.button--filled.button--info:hover {
  background: #0367d8;
  border-color: #0367d8;
}
.button--filled.button--success-dark {
  background: #0f9151;
  color: #fefefe;
  border-color: #0f9151;
}
.button--filled.button--success-dark:hover {
  background: #0a6337;
  border-color: #0a6337;
}
.button--filled.button--charity-animal-darker {
  background: #20b13b;
  color: #fefefe;
  border-color: #20b13b;
}
.button--filled.button--charity-animal-darker:hover {
  background: #19862d;
  border-color: #19862d;
}
.button--outlined.button--success {
  color: #00ff80;
  border-color: #00ff80;
}
.button--outlined.button--success:hover {
  color: #22222a;
  border-color: #00cc66;
  background: #00cc66;
}
.button--outlined.button--dangerous {
  color: #e86464;
  border-color: #e86464;
}
.button--outlined.button--dangerous:hover {
  color: #22222a;
  border-color: #e13737;
  background: #e13737;
}
.button--outlined.button--warning {
  color: #ffa200;
  border-color: #ffa200;
}
.button--outlined.button--warning:hover {
  color: #22222a;
  border-color: #cc8200;
  background: #cc8200;
}
.button--outlined.button--light-grey {
  color: #a5a5b4;
  border-color: #a5a5b4;
}
.button--outlined.button--light-grey:hover {
  color: #22222a;
  border-color: #89899d;
  background: #89899d;
}
.button--outlined.button--checkout {
  color: #f0b342;
  border-color: #f0b342;
}
.button--outlined.button--checkout:hover {
  color: #22222a;
  border-color: #eca013;
  background: #eca013;
}
.button--outlined.button--white {
  color: white;
  border-color: white;
}
.button--outlined.button--white:hover {
  color: #22222a;
  border-color: #e6e6e6;
  background: #e6e6e6;
}
.button--outlined.button--grey {
  color: #646473;
  border-color: #646473;
}
.button--outlined.button--grey:hover {
  color: #22222a;
  border-color: #4c4c57;
  background: #4c4c57;
}
.button--outlined.button--black-low {
  color: #42424d;
  border-color: #42424d;
}
.button--outlined.button--black-low:hover {
  color: #22222a;
  border-color: #2a2a32;
  background: #2a2a32;
}
.button--outlined.button--info {
  color: #137ffb;
  border-color: #137ffb;
}
.button--outlined.button--info:hover {
  color: #22222a;
  border-color: #0367d8;
  background: #0367d8;
}
.button--outlined.button--success-dark {
  color: #0f9151;
  border-color: #0f9151;
}
.button--outlined.button--success-dark:hover {
  color: #22222a;
  border-color: #0a6337;
  background: #0a6337;
}
.button--outlined.button--charity-animal-darker {
  color: #20b13b;
  border-color: #20b13b;
}
.button--outlined.button--charity-animal-darker:hover {
  color: #22222a;
  border-color: #19862d;
  background: #19862d;
}
.button:disabled, .button:disabled.disabled:hover, .button:disabled.disabled:active, .button.disabled, .button.disabled.disabled:hover, .button.disabled.disabled:active {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}
.button:disabled.button--ghost, .button:disabled.disabled:hover.button--ghost, .button:disabled.disabled:active.button--ghost, .button.disabled.button--ghost, .button.disabled.disabled:hover.button--ghost, .button.disabled.disabled:active.button--ghost {
  border-color: transparent;
}

.primary {
  border: 2px solid #fff;
  background-color: #fff;
  color: black;
  transition: all 0.1s ease-in-out;
}
.primary:hover, .primary:active, .primary:focus {
  transition: all 0.1s ease-in-out;
  background-color: #bfbfbf;
  border: 2px solid #bfbfbf;
  color: black;
}

.cta {
  border: 2px solid #60ffcc;
  background-color: #60ffcc;
  color: black;
  transition: all 0.1s ease-in-out;
}
.cta:hover {
  transition: all 0.1s ease-in-out;
  background-color: #00e098;
  border: 2px solid #00e098;
  color: black;
}
.cta__focus {
  box-shadow: 0 0 5px 1px #fff;
}

.dangerous {
  background-color: #e86464;
  border: 2px solid #e86464;
  color: black;
}
.dangerous:hover {
  background-color: #b11b1b;
  border: 2px solid #b11b1b;
  color: black;
}

.safe {
  background-color: #95fecb;
  border: 2px solid #95fecb;
  color: black;
}
.safe:hover {
  background-color: #17fd8e;
  border: 2px solid #17fd8e;
  color: black;
}

.warning {
  border: 2px solid #f4da86;
  background-color: #f4da86;
  color: #22222a;
}
.warning:hover {
  background-color: #eec849;
  border: 2px solid #eec849;
  color: #22222a;
}

.button--icon--vertical {
  flex-direction: column;
}
.button--icon--nolabel {
  padding: 9px;
}
.button--icon--nolabel.button--sm {
  padding: 6px;
}
.button--icon--nolabel.button--lg {
  padding: 12px;
}
.button--icon--nolabel.button--xl {
  padding: 15px;
}
.button--icon__icon--left {
  margin-right: 6px;
}
.button--icon__icon--right {
  margin-left: 6px;
}
.button--icon.button--icon--simple {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 12px;
  line-height: 1;
  border: none;
}
.button--icon.button--icon--simple:focus {
  box-shadow: none;
  outline: 1px dashed #353540;
}
.button--icon.button--icon--simple:hover {
  background-color: transparent;
  outline: none;
}
@media (max-width: 1023px) {
  .button--icon--responsive.button {
    padding: 12px;
    line-height: 1;
  }
  .button--icon--responsive.button--sm {
    padding: 9px;
  }
  .button--icon--responsive.button--lg {
    padding: 15px;
  }
  .button--icon--responsive.button--xl {
    padding: 18px;
  }
  .button--icon--responsive .button--icon__label {
    display: none;
  }
  .button--icon--responsive .button--icon__icon--left,
.button--icon--responsive .button--icon__icon--right {
    margin: 0;
  }
}
.button--icon--grey {
  width: 24px;
  height: 24px;
  position: relative;
  border: 1px solid #fff;
  padding: 0px;
  padding-top: 1px;
}
.button--icon--grey:disabled {
  border-color: rgba(255, 255, 255, 0.2);
}
.button--icon--grey > svg {
  position: absolute;
  top: 4px;
  left: 4px;
}

.buttonrow {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}
.buttonrow.equally {
  flex-direction: column;
}
.buttonrow.equally .button {
  flex: 0 0 100%;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
  margin-left: 0;
}
@media (min-width: 64em) {
  .buttonrow.align-right {
    justify-content: flex-end;
  }
}
.buttonrow .button {
  width: 100%;
  margin-bottom: 12px;
}
@media (min-width: 64em) {
  .buttonrow {
    flex-direction: row;
    justify-content: space-between;
  }
  .buttonrow .button {
    width: auto;
    margin-bottom: 0;
  }
}

.responsive {
  display: block;
  width: 100%;
}
@media (min-width: 64em) {
  .responsive {
    display: inline-block;
    width: auto;
  }
}

.block {
  display: block;
  width: 100%;
}

@media (min-width: 64em) {
  .buttongroup {
    display: flex;
  }
}
.buttongroup > button {
  border-radius: 0;
  margin: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 1px;
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}
.buttongroup > button:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.buttongroup > button:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media (min-width: 64em) {
  .buttongroup > button {
    display: inline-block;
    width: auto;
  }
  .buttongroup > button + .button {
    margin-left: 1px;
  }
  .buttongroup > button:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
  }
  .buttongroup > button:last-child {
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
  }
}
.buttongroup > button:hover {
  border-width: 0;
}

.asLink {
  border: none;
  padding: 0;
  background-color: transparent;
  text-transform: none;
  font-weight: inherit;
  font-size: inherit;
}
.asLink:hover {
  background-color: transparent;
  color: #60ffcc;
}
.asLink:focus {
  box-shadow: none;
}

.weird {
  background-color: #0d0d10;
  border: none;
}

.asRadio {
  border-radius: 25px;
  border: none;
  background-color: #22222a;
}
.asRadio:hover {
  background-color: #393946;
}
.asRadio:focus {
  box-shadow: none;
}
.asRadio::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-left: -2em;
  margin-right: 1em;
  background-color: transparent;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 0 2px #22222a, 0 0 0 4px #646472;
}
.asRadio.selected::before {
  /* stylelint-disable */
  box-shadow: 0 0 0 3px #646472, 0 0 0 4px #22222a, inset 0 0 0 10px #60ffcc;
  /* stylelint-enable */
}

.selected {
  background-color: #646472;
}
.selected:hover {
  background-color: #28282e;
}

.small {
  padding: 5px 24px;
}

.loading {
  position: relative;
}

.withInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  background-color: #fff;
  color: #22222a;
}
.withInfo.disabled {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}
.withInfo > div {
  flex: 1;
  background-color: #22222a;
  padding: 10px 12px;
  margin-left: 24px;
  color: #fff;
}
.withInfo > div img {
  max-width: 18px;
  margin-right: 12px;
}
.withInfo:not(.disabled):hover {
  background-color: #f2f2f2;
}

.inverted {
  background: #0d0d10;
}

.button--image-icon {
  min-height: 50px;
  position: relative;
  text-align: left;
  padding-left: 50px;
  transition: 0.5s background;
}
.button--image-icon::before {
  background: var(--button-image-icon-urlImage);
  background-size: 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 12px;
  top: 6px;
}
.button--image-icon .button__wrapper-icon {
  position: absolute;
  right: 12px;
  top: 6px;
  font-size: 24px;
}

.button-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.button-list .button + .button {
  margin-left: 0;
}
.button-list--col {
  flex-direction: column;
}
.button-list--centered {
  align-items: center;
  justify-content: center;
}
.button-list--right {
  justify-content: flex-end;
}