/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.set-list-item {
  border-radius: 3px;
  border: 1px solid #41414d;
  cursor: pointer;
  display: flex;
  transition: border 0.3s ease-in-out;
  color: #fff;
  background: #41414d;
}
.set-list-item__image {
  width: 66px;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.set-list-item__image img {
  border-radius: 3px 0 0 3px;
  max-height: 100%;
  max-width: 100%;
}
@media (min-width: 41.87em) {
  .set-list-item__image {
    width: 80px;
    height: 80px;
  }
}
.set-list-item__content {
  flex: 1;
  align-self: center;
  margin-left: 12px;
}
.set-list-item__content div {
  font-size: 12px;
}
@media (min-width: 41.87em) {
  .set-list-item__content {
    margin-left: 36px;
  }
}
.set-list-item__title {
  font-weight: 300;
  display: block;
  margin-bottom: 2px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 390px) {
  .set-list-item__title {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (min-width: 41.87em) {
  .set-list-item__title {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 4px;
  }
}
.set-list-item__tags {
  list-style: none;
  padding: 0;
}
.set-list-item__tags li {
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  padding: 1px 14px;
  margin-right: 10px;
  margin-bottom: 3px;
  color: #fff;
  background: #22222a;
}
.set-list-item__button {
  background: #22222a;
  border: 0;
  border-radius: 0 2.5px 2.5px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  transition: background 0.3s ease-in-out;
}
.set-list-item__button svg {
  width: 20px;
  height: 16px;
  fill: #eee;
}
.set-list-item:hover {
  border: 1px solid #15cd72;
}
.set-list-item:hover .set-list-item__button {
  background: #15cd72;
}
.set-list-item:hover .set-list-item__button svg {
  fill: #22222a;
}

.set-list-item--active {
  border: 1px solid #15cd72;
}
.set-list-item--active .set-list-item__title, .set-list-item--active .set-list-item__tags li {
  color: #fff;
}
.set-list-item--active .set-list-item__button {
  background: #15cd72;
  border-radius: 0;
}
.set-list-item--active .set-list-item__button svg {
  fill: #22222a;
}