/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.tracker-panel {
  position: fixed;
  isolation: isolate;
  bottom: 0;
  padding-bottom: 12px;
  left: 12px;
  right: 12px;
  margin: auto;
  min-width: 250px;
  width: fit-content;
  z-index: 200;
  transition: transform 0.3s ease-in;
  transform: translate(0);
}
.tracker-panel--collapsed {
  transform: translateY(100%);
}
.tracker-panel--collapsed .tracker-panel__collapse {
  transform: translateY(-5px);
}
.tracker-panel--collapsed .tracker-panel__collapse svg {
  top: -3px;
  transform: rotate(180deg);
  margin-left: 12px;
}
.tracker-panel__content {
  border-radius: 24px;
  padding: 12px 24px;
  background: #22222a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 200;
}
@media (min-width: 41.87em) {
  .tracker-panel__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
@supports (gap: 24px) {
  .tracker-panel__content {
    gap: 12px;
  }
}
@supports not (gap: 24px) {
  .tracker-panel__content:not(:last-child) {
    margin-bottom: 12px;
  }
}
.tracker-panel__collapse {
  position: absolute;
  top: -22px;
  right: 24px;
  padding: 3.6px 12px 6px !important;
  transition: all 0.3s ease;
}
.tracker-panel__collapse, .tracker-panel__collapse:hover {
  color: #fefefe;
  background: #22222a;
  border-color: rgba(255, 255, 255, 0.1);
}
.tracker-panel__collapse svg {
  margin-left: 0;
  transition: all 0.1s ease-in;
}
.tracker-panel__input {
  margin-bottom: 0;
  max-width: 80px;
}
.tracker-panel__container {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.tracker-panel__title {
  margin-bottom: 0;
  font-weight: 600;
}
.tracker-panel__countdown {
  font-variant-numeric: tabular-nums;
  font-size: 2rem;
  border-radius: 24px;
  font-weight: 600;
  text-align: center;
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-right: 24px;
}
.tracker-panel__actions {
  display: flex;
  align-items: flex-end;
}