/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
@media (min-width: 64em) {
  .timer {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 300px;
    min-height: 600px;
    grid-template-areas: "form preview";
  }
}
@media (min-width: 1600px) {
  .timer {
    grid-template-columns: 1fr 400px;
  }
}
.timer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}
.timer__header h2 {
  margin-bottom: 0;
}
.timer__preview {
  grid-area: preview;
}
@media (max-width: 1023px) {
  .timer__preview {
    margin-bottom: 48px;
  }
}
.timer__preview__section {
  width: 100%;
  margin-bottom: 24px;
}
.timer__preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 64em) {
  .timer__preview-content {
    padding: 24px;
  }
}
.timer__url {
  height: 48px;
  border-radius: 12px;
}
.timer__form {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.timer__tooltip {
  padding: 0 !important;
}
.timer .timer__gallery {
  margin-bottom: 0;
}

.timer-provider {
  background-color: #41414d;
  padding: 24px;
}
.timer-provider__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.timer-provider__container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 41.87em) {
  .timer-provider__container {
    flex-direction: row;
  }
}
.timer-provider__container--preview {
  margin-right: 0;
  margin-bottom: 36px;
}
@media (min-width: 41.87em) {
  .timer-provider__container--preview {
    margin-right: 72px;
    margin-bottom: 0;
  }
}

.timer-bar {
  display: flex;
  justify-content: stretch;
  margin-bottom: 3px;
}
.timer-bar--blinking {
  animation: blinker 1s linear infinite;
}
.timer-bar__image {
  max-width: 20px;
  margin-right: 3px;
}
.timer-bar__image img {
  max-height: 32px;
}
.timer-bar__content {
  position: relative;
  border-radius: 3px;
  background: #0d0d10;
  display: flex;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  padding: 0 6px;
  flex: 1 1 100%;
  min-width: 0;
}
.timer-bar__inner {
  animation: bar linear forwards;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.timer-bar__text {
  z-index: 200;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 24px;
}
.timer-bar__countdown {
  z-index: 200;
}

@keyframes bar {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
  0%, 25% {
    background: linear-gradient(180deg, #40a8f4 0%, #095d9a 100%);
  }
  26%, 50% {
    background: linear-gradient(180deg, #7bc944 0%, #447522 100%);
  }
  51%, 75% {
    background: linear-gradient(180deg, #ffa727 0%, #945801 100%);
  }
  76%, 100% {
    background: linear-gradient(180deg, #d42ae6 0%, #7e118a 100%);
  }
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}