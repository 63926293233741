/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.footer {
  position: relative;
  padding: 24px 12px;
  font-size: 0.75rem;
}
@media (min-width: 64em) {
  .footer {
    padding: 24px;
  }
}
.footer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .footer__content {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
}
.footer__links {
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1199px) {
  .footer__links {
    justify-content: center;
  }
}
.footer__links > a {
  display: block;
  padding: 9px 12px;
}
.footer__links--blog {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  color: #60ffcc;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.footer__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}
@media (min-width: 1200px) {
  .footer__right {
    align-items: flex-end;
  }
}
.footer__languages {
  margin-bottom: 12px;
  text-align: center;
}
@media (min-width: 1200px) {
  .footer__languages {
    text-align: right;
  }
}
.footer__copyright {
  display: flex;
  align-items: center;
}
.footer__copyright__brand {
  white-space: nowrap;
  margin-right: 18px;
}
.footer__social {
  display: flex;
}
.footer__social > a {
  font-size: 28px;
  padding: 0 6px;
}
.footer__social > a[title=YouTube] {
  font-size: 30px;
}