/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.achievement {
  position: relative;
  width: 708px;
  max-width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  background: #22222a;
  border-radius: 6px;
  overflow: hidden;
  color: #fff;
  padding: 15px 24px;
}
.achievement .info-wrapper {
  width: 488px;
  max-width: calc(96% - 114px);
  display: flex;
  flex-direction: column;
}
.achievement .mobile-icon {
  display: none;
}
.achievement h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 12px;
}
.achievement .description {
  color: #a5a8ac;
  font-size: 14px;
  margin: 0 0 12px;
}
.achievement .desktop-icon {
  width: 114px;
  margin: 0 0 auto;
  padding: 0 0 15px;
}
.achievement .reward {
  margin-bottom: 10px;
}
.achievement .reward svg {
  font-size: 17px;
}
.achievement .reward .chevron {
  display: none;
}
.achievement .not-completed {
  opacity: 0.3;
}
.achievement__progressbar {
  position: absolute !important;
  height: 15px !important;
  border-radius: 0 0 6px 6px !important;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 669px) {
  .achievement {
    max-width: calc(100% - 10px);
    padding: 12px 10px 15px;
  }
  .achievement .info-wrapper {
    width: 100%;
    max-width: unset;
  }
  .achievement .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .achievement .desktop-icon {
    display: none;
  }
  .achievement .mobile-icon {
    display: block;
    width: 12vw;
    margin-right: 12px;
  }
  .achievement h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .achievement .expires {
    font-weight: 400;
    margin-bottom: 0;
  }
  .achievement .description {
    margin-bottom: 6px;
  }
  .achievement .reward {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 6px;
  }
  .achievement .reward p {
    margin: 0;
    line-height: 1.4em;
  }
  .achievement .reward svg.chevron {
    margin: 0 0 0 auto;
    display: inline-block;
    width: 30px;
    height: 22px;
    border-radius: 3px;
    background: #8e9398;
    padding: 4px;
    transition: transform 0.4s;
    fill: #0d0d10;
  }
  .achievement .reward svg.opened {
    transform: rotate(180deg);
  }
}

.expires {
  color: #f35;
  font-size: 12px;
  font-weight: 700;
}
.expires.is-completed {
  color: #15cd72;
}

.achievement.skeleton .desktop-icon {
  margin-bottom: 0;
}
.achievement.skeleton .mobile-icon span {
  width: 12vw;
  height: 12vw;
}
.achievement.skeleton .reward {
  margin-bottom: 14px;
}
@media (max-width: 669px) {
  .achievement.skeleton .reward {
    display: none;
  }
}