/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.validate-email__wrapper {
  background-color: #0d0d10;
  background-image: url("https://static.streamloots.com/99d63dc9-60b1-4376-aea9-08592f04781d/ilustrations/stars.png");
  background-size: cover;
}
.validate-email__content {
  width: 100%;
  max-width: 1237px;
  margin: auto;
  z-index: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.validate-email__panel {
  position: relative;
  margin: 0 auto 24px;
  padding: 24px;
  background: #22222a;
  max-width: 536px;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
}
@media (min-width: 41.87em) {
  .validate-email__panel {
    padding: 24px 48px;
    margin: 0 24px 24px;
  }
}
.validate-email__panel img {
  transition: transform 0.1s ease-in;
}
.validate-email__panel--has-hover:hover {
  cursor: pointer;
  box-shadow: inset 0 -5px 0 0 #60ffcc;
}
.validate-email__panel--has-hover:hover .validate-email__button {
  background: #41414d;
  color: #fff;
  border: none;
}
.validate-email__panel--has-hover:hover .validate-email__button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.validate-email__panel--has-hover:hover img {
  transform: scale(1.1);
}
.validate-email__panel__title {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
}
.validate-email__panel__description {
  margin-top: 24px;
  margin-bottom: 24px;
  opacity: 0.5;
  text-align: center;
}
.validate-email__button {
  padding: 24px 36px;
  display: block;
  width: 100%;
  background: #41414d;
  border: none;
  color: #eee;
}
.validate-email__button:focus {
  box-shadow: none;
}
.validate-email__button:hover {
  border: none;
}