/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
:global .rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  opacity: 1;
  min-width: 150px;
  max-width: 220px;
}
:global .rc-tooltip-hidden {
  display: none;
}
:global .rc-tooltip-inner {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  box-shadow: 0 -3px 7px 1px #0e0e11 !important;
  padding: 6px 12px;
  color: black !important;
  text-align: center;
  text-decoration: none;
  background-color: #fffffff7 !important;
  border-radius: 6px;
}
:global .rc-tooltip-placement-top .rc-tooltip-inner::before, :global .rc-tooltip-placement-bottom .rc-tooltip-inner::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  content: "";
  left: 50%;
  height: 0;
  width: 0;
}
:global .rc-tooltip-placement-top .rc-tooltip-inner {
  transform: translateY(-5px);
}
:global .rc-tooltip-placement-top .rc-tooltip-inner::before {
  top: 100%;
  border-top: solid 8px #fffffff7;
}
:global .rc-tooltip-placement-right::before,
:global .rc-tooltip-placement-left::before {
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  position: absolute;
  content: "";
  top: 50%;
  margin-top: -8px;
}
:global .rc-tooltip-placement-right {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}
:global .rc-tooltip-placement-right::before {
  right: 100%;
  /* To the left of the tooltip */
  border-left: solid 8px transparent;
  border-right: solid 8px #fffffff7;
}
:global .rc-tooltip-placement-bottom {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}
:global .rc-tooltip-placement-bottom::before {
  bottom: 100%;
  border-bottom: solid 8px #fffffff7;
}
:global .rc-tooltip-placement-left {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}
:global .rc-tooltip-placement-left::before {
  left: 100%;
  /* To the left of the tooltip */
  border-right: solid 8px transparent;
  border-left: solid 8px #fffffff7;
}