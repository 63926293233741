/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.dropdown__input, .number__input, .textarea, .input {
  display: inline-block;
  border: 1px solid #353540;
  border-radius: 6px;
  width: 100%;
  transition: border 0.1s linear, background-color 0.1s linear;
  padding: 6px 12px;
  line-height: 1.5;
  margin-bottom: 24px;
  background-color: #41414d;
  color: #fff;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-family: "Open Sans", sans-serif;
}
.dropdown__input:hover, .number__input:hover, .textarea:hover, .input:hover {
  border-color: #fefefe;
}
.dropdown__input:focus, .number__input:focus, .textarea:focus, .input:focus {
  border-color: #3845ff;
  box-shadow: inset 0 0 0 1px #3845ff;
  outline: 0;
}
.dropdown__input::placeholder, .number__input::placeholder, .textarea::placeholder, .input::placeholder {
  color: #94a8b1;
}
[disabled].dropdown__input, [disabled].number__input, [disabled].textarea, [disabled].input {
  background-color: #343a40;
  cursor: not-allowed;
  color: #96a0aa;
  opacity: 0.5;
}
[disabled].dropdown__input:hover, [disabled].number__input:hover, [disabled].textarea:hover, [disabled].input:hover {
  border-color: #353540;
}

.input__info {
  display: flex;
  width: 100%;
  margin-top: -22px;
  margin-bottom: 24px;
  font-weight: 400;
}
.input__info__error, .input__info__hint {
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  flex: 1;
}
.input__info__error {
  color: #e64759;
}
.input__info__error + .input__info__length {
  flex-basis: 3em;
  flex-grow: 0;
}
.input__info__hint {
  color: #9e9e9e;
}
.input__info__length {
  flex: 1;
  text-align: right;
}

.textarea {
  min-height: 60px;
}
.textarea:hover {
  border-color: #fefefe;
}

.label {
  display: block;
  color: #cfd8dc;
  font-size: 0.75rem;
  margin-bottom: 6px;
  font-weight: 400;
}

.label__error {
  color: #e64759;
}
.label--flex {
  display: flex;
  align-items: center;
}

.input:invalid,
.textarea:invalid {
  box-shadow: none;
}

.input--big {
  height: 42px;
}
.input--small {
  height: 30px;
}
.input--grey {
  background: rgba(100, 100, 115, 0.2);
  border: 1px solid #646472;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #fefefe;
}

.textarea--grey {
  background: rgba(100, 100, 115, 0.2);
  border: 1px solid #646472;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #fefefe;
  resize: vertical;
}

.checkbox {
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 12px;
  cursor: pointer;
}
.checkbox--reverse {
  flex-direction: row-reverse;
  text-align: right;
}
.checkbox__inline {
  width: auto;
}
.checkbox__checkbox {
  position: relative;
  margin-top: 2px;
  width: 18px;
  height: 18px;
  padding: 0;
  line-height: normal;
  border-radius: 3px;
  border: 1px solid #353540;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.1s ease-in;
  box-shadow: inset 0 0 0 0 #00f9a9;
  flex: 0 0 18px;
}
.checkbox input[type=checkbox],
.checkbox input[type=radio] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.checkbox input[type=checkbox]:checked + .checkbox__checkbox,
.checkbox input[type=radio]:checked + .checkbox__checkbox {
  background-color: #137ffb;
  border-color: #fff;
}
.checkbox input[type=checkbox]:checked + .checkbox__checkbox::after,
.checkbox input[type=radio]:checked + .checkbox__checkbox::after {
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 14' style='enable-background:new 0 0 14 14;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M5.2,11.5l-4-4L3,5.6l2.2,2.2l6.4-6.4l1.8,1.8L5.2,11.5z'/%3E%3C/g%3E%3C/svg%3E%0A");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -43%);
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
}
.checkbox input[type=checkbox]:disabled + .checkbox__checkbox,
.checkbox input[type=radio]:disabled + .checkbox__checkbox {
  border-color: #070708;
  cursor: not-allowed;
  background-color: #999999;
}
.checkbox input[type=checkbox]:disabled ~ .checkbox__label,
.checkbox input[type=radio]:disabled ~ .checkbox__label {
  opacity: 0.5;
  cursor: not-allowed;
}
.checkbox input[type=checkbox]:disabled:checked,
.checkbox input[type=radio]:disabled:checked {
  background-color: #034fa6;
}
.checkbox input[type=checkbox]:focus + .checkbox__checkbox,
.checkbox input[type=radio]:focus + .checkbox__checkbox {
  box-shadow: 0 0 3px 1px #fff;
  border: 1px solid #fff;
}
.checkbox input[type=radio] + .checkbox__checkbox {
  border-radius: 100%;
}
.checkbox input[type=radio] + .checkbox__checkbox::after {
  content: none;
}
.checkbox input[type=radio]:checked + .checkbox__checkbox::after {
  content: none;
}
.checkbox__label {
  margin-bottom: 0;
  font-size: 0.875rem;
  user-select: none;
  flex: 1;
}
.checkbox__big .checkbox__checkbox {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  flex: 0 0 20px;
}
.checkbox__big .checkbox__checkbox::after {
  transform: translate(-51%, -42%);
  width: 14px;
  height: 14px;
}

.switch {
  cursor: pointer;
  padding: 6px 0;
  user-select: none;
  display: block;
}
.switch__input {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
}
.switch__input:checked + .switch__slider {
  background-color: #137ffb;
}
.switch__input:checked + .switch__slider::after {
  transform: translateX(1em);
}
.switch__input[disabled] + .switch__slider {
  background-color: #343a40;
}
.switch__input[disabled] + .switch__slider::after {
  background-color: #343a40;
}
.switch__slider {
  position: relative;
  display: inline-block;
  background-color: #a5a5b4;
  width: 2em;
  height: 1em;
  border-radius: 48px;
  transition: all 0.3s ease-in-out;
  margin-right: 12px;
  vertical-align: middle;
}
.switch__slider::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.number {
  margin-bottom: 24px;
  display: flex;
  position: relative;
  max-width: 170px;
}
.number__button-left {
  margin-right: 12px;
}
.number__button-right {
  margin-left: 12px;
}
.number__input {
  flex: 2;
  margin-bottom: 0;
  min-width: 0;
  -moz-appearance: textfield;
  /* Firefox */
}
.number__input::-webkit-outer-spin-button, .number__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number__input--big {
  height: 42px;
}
.number__input--small {
  height: 30px;
}
.number__input--grey {
  background: rgba(100, 100, 115, 0.2);
  border: 1px solid #646472;
  box-sizing: border-box;
  border-radius: 6px;
  width: 90px;
  text-align: center;
  color: #fefefe;
}
.number > button {
  flex-shrink: 0;
}
.number--grey {
  align-items: center;
  padding: 0px;
  height: auto;
}
.number__units {
  margin-left: 6px;
}
.number__units--disabled {
  opacity: 0.5;
}

.dropdown {
  position: relative;
}
.dropdown__big {
  position: absolute;
  right: 10px;
  top: 14px;
}
.dropdown__small {
  position: absolute;
  right: 10px;
  top: 8px;
}
.dropdown__arrow {
  position: absolute;
  right: 18px;
  margin-top: -2.5px;
  top: 50%;
  cursor: pointer;
}
.dropdown__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown__wrapper svg {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 6px;
  font-size: 1.125rem;
}
.dropdown__wrapper--iconMargin input {
  padding-left: 36px !important;
}
.dropdown__input {
  position: relative;
  height: 35px;
  cursor: pointer;
  padding-right: 36px;
}
.dropdown__input--nomargin {
  margin-bottom: 0;
}
.dropdown__input--small {
  height: 30px;
  padding-top: 4px;
}
.dropdown__input--big {
  height: 42px;
  padding-top: 9px;
}
.dropdown__input--grey {
  padding-bottom: 0px;
  background: rgba(100, 100, 115, 0.2);
  border: 1px solid #646472;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #fefefe;
}
.dropdown__placeholder {
  color: #94a8b1;
}
.dropdown__disabled {
  background-color: #343a40;
  cursor: not-allowed;
  color: #96a0aa;
}
.dropdown__disabled--grey {
  opacity: 0.4;
}
.dropdown__disabled:hover {
  border-color: #353540;
}
.dropdown__options__wrapper {
  position: absolute;
  top: calc(100% - 24px);
  left: 0;
  right: 0;
  z-index: 300;
}
.dropdown__options {
  background: #2a2a31;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-left: 1px solid #353540;
  border-right: 1px solid #353540;
  border-bottom: 1px solid #353540;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.6);
}
.dropdown__option {
  padding: 6px 12px;
  cursor: pointer;
}
.dropdown__option-selected {
  color: #60ffcc;
}
.dropdown__option-focus {
  background: #41414d;
}
.dropdown--nomargin .dropdown__options__wrapper {
  top: 100%;
}

.inputNumberInline {
  position: relative;
  width: 100%;
}
.inputNumberInline__input {
  text-align: right;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  padding-right: 36px;
  -moz-appearance: textfield;
}
.inputNumberInline__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputNumberInline__spinner {
  position: absolute;
  cursor: pointer;
  color: #eee;
  background-color: transparent;
  right: 12px;
  font-size: 0.65rem;
  opacity: 0.8;
}
.inputNumberInline__spinner img {
  width: 10px;
}
.inputNumberInline__spinner:hover {
  opacity: 1;
}
.inputNumberInline__spinner__increment {
  top: 3px;
}
.inputNumberInline__spinner__decrement {
  top: 17px;
}