/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.alert-provider section {
  margin-bottom: 36px;
}
.alert-provider__explanation-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 36px;
  background: transparent linear-gradient(125deg, #3946ff 0%, #60ffcc 100%) 0 0 no-repeat padding-box;
  border-radius: 12px;
  min-width: 240px;
  max-height: 150px;
  min-height: 96px;
}
.alert-provider__explanation-header svg {
  font-size: 2.5rem;
}
.alert-provider__set-up {
  text-align: center;
}
@media (min-width: 41.87em) {
  .alert-provider__set-up {
    padding: 24px 48px;
  }
}
.alert-provider__set-up__tip {
  font-size: 0.75rem;
  padding-top: 12px;
  font-style: italic;
}
.alert-provider__form-header {
  font-size: 1.125rem;
}
.alert-provider__section {
  border-bottom: 0;
  border-top: 1px solid #41414d;
  padding-top: 36px;
  margin-bottom: 12px;
}
.alert-provider__section-reduced {
  width: 50%;
}
.alert-provider__input-label {
  font-size: 0.75rem;
  color: #a5a5b4;
}
.alert-provider__inputNumberSimple {
  border-radius: 6px;
  text-align: left;
}
.alert-provider__voice {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;
  width: 60%;
}
.alert-provider__tips-image {
  background-image: url("./assets/img.png");
  background-position: center;
  width: 100%;
}
.alert-provider__tips-panel {
  position: sticky;
  top: var(--top-menu-height);
  display: none;
}
.alert-provider__tips-panel h2 {
  font-size: 1rem;
  margin-bottom: 24px;
}
.alert-provider__tips-panel button {
  justify-content: flex-start;
  line-height: 1.3;
  text-align: left;
  font-weight: 600;
  font-size: 0.875rem;
}
@media (min-width: 1200px) {
  .alert-provider__tips-panel {
    display: block;
  }
}
.alert-provider__text-config {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
}
.alert-provider__sound {
  max-width: 320px;
}
.alert-provider__textarea {
  border-radius: 12px;
}
.alert-provider__streamlabs {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.alert-provider__streamlabs__icon {
  font-size: 1.125rem;
  display: inline-block;
  margin-right: 12px;
}
.alert-provider__text-preview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 24px;
}
.alert-provider__text-preview__preview {
  background: #41414d;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alert-provider__text-preview__text {
  padding: 24px;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;
}