/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.no-overflow {
  overflow: hidden;
}

.alert {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
}
.alert__icon {
  font-size: 1.125rem;
  margin-right: 12px;
}
.alert--error {
  background-color: #f3b1b1;
  color: #22222a;
}
.alert--error svg {
  color: #8b1515;
}
.alert--error a,
.alert--error button {
  color: #22222a;
}
.alert--error a:hover,
.alert--error button:hover {
  color: black;
}
.alert--info {
  background-color: #dfedfe;
  color: #22222a;
}
.alert--info svg {
  color: #11498b;
}
.alert--info a,
.alert--info button {
  color: #22222a;
}
.alert--info a:hover,
.alert--info button:hover {
  color: black;
}
.alert--warning {
  background-color: #f9edc3;
  color: #27272e;
}
.alert--warning a,
.alert--warning button {
  color: #27272e;
}
.alert--warning a:hover,
.alert--warning button:hover {
  color: black;
}
.alert--success {
  background-color: #deffef;
  color: #22222a;
}
.alert--success svg {
  color: #0f9151;
}
.alert--success a,
.alert--success button {
  color: #22222a;
}
.alert--success a:hover,
.alert--success button:hover {
  color: black;
}
.alert--cta {
  background-color: #202574;
  color: #fff;
}
.alert--cta a {
  color: #fff;
}
.alert__close {
  color: #fff;
  padding: 6px 12px;
}
.alert a {
  text-decoration: underline;
}

.alert--floating {
  min-width: 300px;
}
.alert--floating--error {
  border: 1px solid #f87f8a;
  background-color: #ff3547;
}
.alert--floating--info {
  border: 1px solid #137ffb;
  background-color: #4495f3;
}
.alert--floating--warning {
  border: 1px solid #a87000;
  background-color: #fb3;
}
.alert--floating--success {
  border: 1px solid #008737;
  background-color: #00c851;
}
.alert--floating__content {
  flex: 1;
  padding-right: 12px;
}

.alert--full {
  width: 90%;
  padding: 48px;
  text-align: center;
}
@media (min-width: 64em) {
  .alert--full {
    width: 40%;
  }
}
.alert--full__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
}
@media (min-width: 64em) {
  .alert--full__overlay {
    background: rgba(0, 0, 0, 0.95);
  }
  .alert--full__overlay::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    clip-path: polygon(0 30%, 100% 25%, 100% 70%, 0 65%);
    background: linear-gradient(90deg, rgba(9, 9, 121, 0) 0%, rgba(9, 9, 121, 0.7) 20%, #090979 50%, rgba(9, 9, 121, 0.6979166667) 80%, rgba(0, 212, 255, 0) 100%);
  }
}
.alert--full__overlay--error {
  background-color: #e64759;
}
@media (min-width: 64em) {
  .alert--full__overlay--error {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.alert--full__overlay--error::after {
  background: linear-gradient(90deg, rgba(230, 71, 89, 0) 0%, rgba(230, 71, 89, 0.2) 20%, rgba(230, 71, 89, 0.5) 40%, rgba(230, 71, 89, 0.5) 60%, rgba(230, 71, 89, 0.2) 80%, rgba(230, 71, 89, 0) 100%);
}
.alert--full__overlay--info {
  background-color: #137ffb;
}
@media (min-width: 64em) {
  .alert--full__overlay--info {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.alert--full__overlay--info::after {
  background: linear-gradient(90deg, rgba(19, 127, 251, 0) 0%, rgba(19, 127, 251, 0.2) 20%, rgba(19, 127, 251, 0.5) 40%, rgba(19, 127, 251, 0.5) 60%, rgba(19, 127, 251, 0.2) 80%, rgba(19, 127, 251, 0) 100%);
}
.alert--full__overlay--warning {
  background-color: #ffa200;
}
@media (min-width: 64em) {
  .alert--full__overlay--warning {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.alert--full__overlay--warning::after {
  background: linear-gradient(90deg, rgba(255, 162, 0, 0) 0%, rgba(255, 162, 0, 0.2) 20%, rgba(255, 162, 0, 0.5) 40%, rgba(255, 162, 0, 0.5) 60%, rgba(255, 162, 0, 0.2) 80%, rgba(255, 162, 0, 0) 100%);
}
.alert--full__overlay--success {
  background-color: #15cd72;
}
@media (min-width: 64em) {
  .alert--full__overlay--success {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.alert--full__overlay--success::after {
  background: linear-gradient(90deg, rgba(21, 205, 114, 0) 0%, rgba(21, 205, 114, 0.2) 20%, rgba(21, 205, 114, 0.5) 40%, rgba(21, 205, 114, 0.5) 60%, rgba(21, 205, 114, 0.2) 80%, rgba(21, 205, 114, 0) 100%);
}
.alert--full__content {
  width: calc(100% - 24px);
  padding: 96px 24px;
  background-color: transparent;
  text-align: center;
}
@media (min-width: 64em) {
  .alert--full__content {
    max-width: 600px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}
.alert--full__title {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.alert--full__title svg {
  height: 1.4em;
  width: 1.4em;
}
.alert--full__message {
  font-size: 0.875rem;
  margin-bottom: 48px;
}

.alert--small {
  font-size: 0.75rem;
}