/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.powers-explanation__logo {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  min-width: 240px;
  min-height: 96px;
  background: transparent linear-gradient(135deg, rgba(57, 70, 255, 0.85) 0%, rgba(96, 255, 204, 0.85) 100%);
}
.powers-explanation__logo__icon {
  position: absolute;
  height: 180px;
  width: auto;
  top: -40px;
  color: #fff;
  opacity: 0.05;
  transform: rotate(45deg);
}
.powers-explanation__logo img {
  width: 160px;
  height: auto;
}
.powers-explanation__container {
  margin-top: 12px;
}
@media (min-width: 769px) {
  .powers-explanation__container {
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
.powers-explanation__button-container {
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
@media (min-width: 769px) {
  .powers-explanation__button-container {
    display: flex;
  }
}
.powers-explanation__button-container__button {
  all: unset;
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
}
.powers-explanation__button-container__button:hover {
  opacity: 0.5;
}