/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.progressbar {
  height: 12px;
  width: 100%;
  background: #41414d;
  color: #eee;
  position: relative;
  display: flex;
  border-radius: 3px;
}
.progressbar--big {
  height: 20px;
  line-height: 20px;
}
.bar .progressbar--big__innerstep {
  font-size: 0.75rem;
}
.progressbar .bar {
  display: inline-block;
  position: relative;
  border-radius: inherit;
}
.progressbar .bar::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  background: transparent linear-gradient(125deg, #ffc05c 0%, #f35 100%) 0% 0% no-repeat padding-box;
  animation: load 1s 0s;
  width: 100%;
  border-radius: inherit;
}
.progressbar .bar__innerstep {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.65rem;
}
.progressbar .bar__innertext {
  display: none;
}
@media (min-width: 64em) {
  .progressbar .bar__innertext {
    display: inline;
  }
}
.progressbar .bar__tick {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 2px solid #626264;
  border-radius: 50%;
  top: calc(50% - 12.5px);
  transform: translateX(-12.5px);
  background: #050a31;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressbar .bar__tick--completed {
  border: 1px solid #157bc7;
  background: #40a8f4;
}
.progressbar .bar__tick--current {
  border-color: #40a8f4;
}
.progressbar--xl {
  height: 34px;
  line-height: 34px;
}
.progressbar--xl .bar__innerstep {
  font-size: 0.9rem;
  font-weight: 600;
}
.progressbar--xl .bar__rounded {
  border-radius: 20px;
}
.progressbar--affiliate {
  background: #050a31;
}
.progressbar--affiliate .bar::before {
  background: #40a8f4;
}
.progressbar--partner .bar::before {
  background: transparent linear-gradient(116deg, #ff9e0a 15.92%, #f25e23 83.09%) 0% 0% no-repeat padding-box;
}
.progressbar--partner .bar__innerstep {
  text-shadow: 0 0px 10px black;
}
.progressbar--rounded {
  border-radius: 12px;
}
.progressbar--rounded .bar::before {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.progressbar--rounded.progressbar--completed .bar::before {
  border-radius: 12px;
}

@keyframes load {
  from {
    width: 0;
  }
}