/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.two-column-panel {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(12, minmax(1px, 1fr));
}
.two-column-panel__start {
  grid-template-areas: "small" "big";
}
@media (min-width: 1200px) {
  .two-column-panel__desktop-break {
    grid-template-areas: "big small";
  }
}
@media (min-width: 1600px) {
  .two-column-panel__large-break {
    grid-template-areas: "big small";
  }
}
.two-column-panel__reverse {
  grid-template-areas: "big" "small";
}
@media (min-width: 1200px) {
  .two-column-panel__reverse__desktop-break {
    grid-template-areas: "big small";
  }
}
@media (min-width: 1600px) {
  .two-column-panel__reverse__large-break {
    grid-template-areas: "big small";
  }
}
.two-column-panel__big {
  grid-area: big;
  grid-column: span 12;
}
@media (min-width: 1200px) {
  .two-column-panel__big__desktop-break {
    grid-column: 1/9;
  }
}
@media (min-width: 1600px) {
  .two-column-panel__big__large-break {
    grid-column: 1/9;
  }
}
.two-column-panel__small {
  grid-area: small;
  grid-column: span 12;
}
@media (min-width: 1200px) {
  .two-column-panel__small__desktop-break {
    grid-column: 9/13;
  }
}
@media (min-width: 1600px) {
  .two-column-panel__small__large-break {
    grid-column: 9/13;
  }
}

.two-column-panel-responsive {
  display: grid;
  width: 100%;
  grid-gap: 24px;
  grid-template-columns: 100%;
}
@media (min-width: 769px) {
  .two-column-panel-responsive {
    grid-template-columns: calc(100% - 350px - 24px) 350px;
  }
}
.two-column-panel-responsive__start {
  grid-template-areas: "small" "big";
}
@media (min-width: 769px) {
  .two-column-panel-responsive__start {
    grid-template-areas: "big small";
  }
}
.two-column-panel-responsive__reverse {
  grid-template-areas: "big" "small";
}
@media (min-width: 769px) {
  .two-column-panel-responsive__reverse {
    grid-template-areas: "big small";
  }
}
.two-column-panel-responsive__big {
  grid-area: big;
}
.two-column-panel-responsive__small {
  grid-area: small;
}