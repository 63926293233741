/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.streamer-list {
  display: grid;
  row-gap: 36px;
  column-gap: 36px;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  width: 100%;
}
@media (max-width: 1280px) {
  .streamer-list {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    column-gap: 24px;
  }
}
@media (max-width: 1023px) {
  .streamer-list {
    column-gap: 24px;
  }
}
@media (max-width: 768px) {
  .streamer-list {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}
@media (max-width: 669px) {
  .streamer-list {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    column-gap: 18px;
  }
}

.card-list {
  display: grid;
  row-gap: 36px;
  column-gap: 36px;
  grid-template-columns: repeat(5, minmax(0px, 1fr));
  width: 100%;
}
@media (max-width: 1280px) {
  .card-list {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    column-gap: 24px;
  }
}
@media (max-width: 1023px) {
  .card-list {
    column-gap: 24px;
  }
}
@media (max-width: 768px) {
  .card-list {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
}
@media (max-width: 669px) {
  .card-list {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    column-gap: 18px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 18px 24px;
  background-color: #22222a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .card {
    padding: 12px 12px 18px;
  }
}
.card:hover {
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.card__header {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  height: 18px;
  width: 100%;
}
.card__header-sep {
  flex-grow: 1;
}
.card__avatar {
  margin-top: 18px;
  border: 2px solid #fff;
  overflow: hidden;
  border-radius: 170px;
  width: 84px;
  height: 84px;
}
@media (max-width: 479px) {
  .card__avatar {
    width: 72px;
    height: 72px;
  }
}
.card__name {
  margin-top: 12px;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  text-align: center;
  display: block;
}
.card__name:hover {
  text-decoration: none;
}
.card__name::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 479px) {
  .card__name {
    font-size: 0.875rem;
  }
}
.card__info {
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: #fff;
  font-size: 0.75rem;
}
@media (max-width: 479px) {
  .card__info {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.card__info svg {
  display: block;
}
.card__info--bordered {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.card__info__love {
  color: #137ffb;
  height: 18px;
  margin-right: 6px;
  min-width: 12px;
}
.card__info--value {
  margin-right: 12px;
}
@media (max-width: 768px) {
  .card__info--value {
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
}
@media (max-width: 479px) {
  .card__info--value {
    flex-grow: 0;
  }
}
.card__dropdown-menu {
  position: relative;
  z-index: 200;
}
.card__data {
  align-items: center;
  display: flex;
  color: #fff;
}
.card__data--icon {
  margin-right: 6px;
}
.card__data--text:first-of-type {
  margin-right: 12px;
}
.card__rank {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 2.4px;
}
.card__rank img {
  max-width: 14px;
}

.streamer {
  border: 1px solid rgba(100, 100, 115, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  position: relative;
  padding: 18px 18px 24px;
}
.streamer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/bg.png");
  z-index: -1;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
.streamer:hover {
  border: 1px solid rgba(100, 100, 115, 0.7);
  cursor: pointer;
}
.streamer__header {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  height: 18px;
  width: 100%;
  margin-bottom: 18px;
}
.streamer__header-sep {
  flex-grow: 1;
}
.streamer__dropdown-menu {
  position: relative;
  z-index: 200;
}
.streamer__avatar {
  border: 2px solid #fff;
  overflow: hidden;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin-bottom: 12px;
}
@media (min-width: 480px) {
  .streamer__avatar {
    width: 84px;
    height: 84px;
  }
}
.streamer__rank {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 2.4px;
  line-height: 0;
}
.streamer__rank img {
  max-width: 14px;
  line-height: 0;
}
.streamer__go {
  margin-top: 12px;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  max-width: fit-content;
  text-align: center;
  display: block;
  margin-bottom: 24px;
}
.streamer__go:hover {
  text-decoration: none;
}
.streamer__go::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 479px) {
  .streamer__go {
    font-size: 0.875rem;
  }
}
.streamer__name {
  font-size: 1.5rem;
  word-break: break-all;
  text-align: center;
  font-weight: 700;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.streamer__subs {
  font-size: 0.875rem;
  color: #fff;
}
.streamer__subs__status {
  font-weight: 700;
}
.streamer__subs--active {
  color: #3845ff;
}
.streamer__subs--renewal {
  color: #ffa200;
}
.streamer__subs--cancel {
  color: #e64759;
}