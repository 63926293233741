/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.imagealert {
  width: 100%;
  max-width: 458px;
  display: flex;
  padding: 6px;
  border-radius: 18px;
  border: 1px solid #353540;
  background-color: #41414d;
  transition: all 0.3s ease-in-out;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 300;
}
@media (min-width: 560px) {
  .imagealert {
    right: 24px;
    bottom: 24px;
  }
}
.imagealert__text {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  text-align: center;
  background-color: #0d0d10;
  color: #eee;
  text-transform: 1.125rem;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
@media (min-width: 560px) {
  .imagealert__text {
    padding: 24px 12px;
  }
}
.imagealert__title {
  font-size: 12px;
  line-height: 1.37;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (min-width: 560px) {
  .imagealert__title {
    font-size: 20px;
  }
}
.imagealert__subtitle {
  font-size: 10px;
  color: #eee;
  margin-bottom: 0;
}
@media (min-width: 560px) {
  .imagealert__subtitle {
    font-size: 14px;
  }
}
.imagealert__description {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin: 0;
  padding: 12px 0;
}
@media (min-width: 560px) {
  .imagealert__description {
    font-size: 0.75rem;
  }
}
.imagealert__description p:last-child {
  margin-bottom: 0;
}
.imagealert__image {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.imagealert__image img {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  height: 100%;
}
.imagealert__video {
  width: 100%;
}
.imagealert__close {
  position: absolute;
  top: 12px;
  right: 24px;
  font-size: 1.125rem;
  color: #fff;
  z-index: 3;
  background: rgba(100, 100, 115, 0.5);
  padding: 6px;
}
.imagealert__close:hover {
  color: #fff;
  background: transparent;
}
.imagealert__close svg {
  font-size: 1.125rem;
}

.rewind-alert.imagealert {
  z-index: 1000001;
}
.rewind-alert.imagealert .imagealert__title {
  color: #fede47;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 12px;
}
.rewind-alert.imagealert .imagealert__description {
  font-size: 0.9rem;
  justify-content: flex-start;
}
.rewind-alert.imagealert .imagealert__close svg {
  fill: #fff;
}