/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.caramel {
  position: fixed;
  left: 0;
  right: 0;
  top: 54px;
  height: 10px;
  background: #0d0d10;
  z-index: 451;
  pointer-events: none;
}
.caramel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 4px;
  background: url("./assets/caramelo.svg");
}
.caramel::before {
  content: "";
  position: absolute;
  left: 12px;
  right: 0;
  background: url("./assets/nieve.svg") repeat-x;
  z-index: 1;
  bottom: -19px;
  height: 30px;
  background-size: 335px;
}
@media (min-width: 33.75em) {
  .caramel::before {
    left: 108px;
  }
}

.lightrope {
  z-index: 450;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  margin: 0;
  top: 48px;
  padding: 0;
  pointer-events: none;
  width: 100%;
}
.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  padding: 0;
  display: inline-block;
  width: 8px;
  height: 18px;
  border-radius: 50%;
  margin: 20px 24px;
  background: #00f7a5;
  box-shadow: 0 2.88px 16px 3px #00f7a5;
  animation-name: flash-1;
  animation-duration: 2s;
}
.lightrope li:nth-child(2n+1) {
  background: aqua;
  box-shadow: 0 2.88px 16px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}
.lightrope li:nth-child(4n+2) {
  background: #f70094;
  box-shadow: 0 2.88px 16px 3px #f70094;
  animation-name: flash-3;
  animation-duration: 1.1s;
}
.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}
.lightrope li:nth-child(3n+1) {
  animation-duration: 1.4s;
}
.lightrope li::before {
  content: "";
  position: absolute;
  background: #222;
  width: 6px;
  height: 5.94px;
  border-radius: 3px;
  top: -2.88px;
  left: 1px;
}
.lightrope li::after {
  content: "";
  top: -9px;
  left: 5px;
  position: absolute;
  width: 52px;
  height: 11.88px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}
.lightrope li:last-child::after {
  content: none;
}
.lightrope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%, 100% {
    background: #00f7a5;
    box-shadow: 0 2.88px 16px 3px #00f7a5;
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0 2.88px 16px 3px rgba(0, 247, 165, 0.2);
  }
}
@keyframes flash-2 {
  0%, 100% {
    background: aqua;
    box-shadow: 0 2.88px 16px 3px aqua;
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0 2.88px 16px 3px rgba(0, 255, 255, 0.2);
  }
}
@keyframes flash-3 {
  0%, 100% {
    background: #f70094;
    box-shadow: 0 2.88px 16px 3px #f70094;
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0 2.88px 16px 3px rgba(247, 0, 148, 0.2);
  }
}