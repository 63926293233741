/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.menu__expandable__header, .menu__header, .menu__link {
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  color: #d3d9de;
  display: block;
  border: none;
  background: transparent;
  text-align: left;
  padding: 12px 24px;
  margin-bottom: 6px;
  border-radius: 12px;
  width: 100%;
}
@media (min-width: 1024px) {
  .menu__expandable__header, .menu__header, .menu__link {
    padding: 12px;
  }
}
@media (min-width: 1300px) {
  .menu__expandable__header, .menu__header, .menu__link {
    padding: 12px 24px;
  }
}
.menu__expandable__header__icon, .menu__header__icon, .menu__link__icon {
  margin-right: 12px;
  font-size: 1.125rem;
}
.menu__expandable__header__icon + span, .menu__header__icon + span, .menu__link__icon + span {
  margin-left: 0;
}
.menu__header {
  color: #a5a5b4;
  font-size: 0.875rem;
}
.menu__link, .menu__expandable__header {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.menu__link--active, .menu__link:hover, .menu__link:focus, .menu__expandable__header--active, .menu__expandable__header:hover, .menu__expandable__header:focus {
  background-color: #41414d;
  color: #fefefe;
  text-decoration: none;
  outline: none;
}
.menu__link:hover, .menu__expandable__header:hover {
  background-color: #f02d4e;
}
.menu__link--separator, .menu__expandable__header--separator {
  padding: 12px 24px;
  border-bottom: 1px solid #353540;
}
.menu__expandable {
  margin-bottom: 0;
}
.menu__expandable .menu__link {
  font-size: 0.875rem;
  padding-top: 9px;
  padding-bottom: 9px;
}
.menu__expandable .menu__link svg {
  visibility: hidden;
}
.menu__expandable__header, .menu__expandable__icon-wrapper {
  display: inline-flex;
  align-items: center;
}
.menu__expandable__icon-wrapper {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
}

.new {
  position: absolute;
  transform: translateX(4px);
  top: 50%;
  margin-top: -8px;
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .new {
    position: relative;
    transform: translateX(0);
    top: 0;
    margin-top: 0;
  }
}