/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.layout {
  /* overflow-x: hidden; */
  min-height: 100vh;
}
.layout__header {
  background: #0d0d10;
  height: 65px;
  left: 0;
  padding: 0 12px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 451;
}
.layout__header--christmas {
  height: 56px;
}
@media (min-width: 64em) {
  .layout__header {
    left: 300px;
    top: 0;
  }
}
@media (min-width: 1024px) {
  .layout__header {
    left: 230px;
  }
}
@media (min-width: 1300px) {
  .layout__header {
    left: 300px;
  }
}
.layout__sidebar__left {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 300px;
  transition: all 0.25s ease-in-out;
  z-index: 600;
  background: #0d0d10;
  position: fixed;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .layout__sidebar__left {
    z-index: 400;
    width: 230px;
  }
}
@media (min-width: 1300px) {
  .layout__sidebar__left {
    width: 300px;
  }
}
.layout__sidebar__left__close {
  position: absolute;
  top: 0;
  right: 0;
}
.layout__sidebar__right {
  transition: all 0.25s ease-in-out;
  z-index: 600;
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.7);
  background-color: #22222a;
  position: fixed;
  right: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  top: 0;
  overflow: hidden;
}
@media (min-width: 64em) {
  .layout__sidebar__right {
    right: 0;
    width: 600px;
  }
}
@media (min-width: 64em) {
  .layout__sidebar__right--large {
    width: 85vw;
  }
}
@media (min-width: 64em) {
  .layout__sidebar__right--medium {
    width: 65vw;
  }
}
.layout__sidebar__right.layout__content {
  z-index: 500;
}
@media (min-width: 64em) {
  .layout__sidebar__right.layout__content {
    width: 450px;
  }
}
.layout__sidebar__right__content {
  padding: 72px 12px 12px;
}
@media (min-width: 41.87em) {
  .layout__sidebar__right__content {
    padding: 72px 24px 24px;
  }
}
.layout__sidebar__right__toolbox {
  background: #22222a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-bottom: 1px solid #353540;
  position: absolute;
  height: 48px;
  width: 100%;
  z-index: 200;
}
.layout__sidebar__right__toolbox--colorful {
  background: transparent linear-gradient(125deg, #ff3355 0%, #7419ff 100%) 0% 0% no-repeat padding-box;
  height: 50px;
  box-shadow: 0px 3px 6px #000;
  border-bottom: none;
}
.layout__sidebar__right__close {
  border: none;
}
.layout__sidebar__right__close svg {
  font-size: 20px;
}
.layout__sidebar__right__title {
  font-weight: 300;
}
.layout__body {
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 65px;
  position: absolute;
  right: 0;
  left: 0;
  background-image: url("./assets/bg-decoration.png");
  background-repeat: no-repeat;
  background-size: 800px;
  background-position: top right;
}
.layout__body.halloween {
  background-image: url("https://static.streamloots.com/b355d1ef-d931-4c16-a48f-8bed0076401b/campaigns/spiderweb-right.svg");
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: 100% 70px;
}
.layout__body--christmas {
  padding-top: 77px;
}
@media (min-width: 64em) {
  .layout__body {
    left: 300px;
    top: 0;
    bottom: 0;
  }
}
@media (min-width: 1024px) {
  .layout__body {
    left: 230px;
  }
}
@media (min-width: 1300px) {
  .layout__body {
    left: 300px;
  }
}
@media (min-width: 64em) {
  .layout__body__toright {
    right: 450px;
  }
}
.layout__content {
  height: 100vh;
  max-height: 100vh;
  top: 0;
}
.layout__overlay {
  opacity: 0.6;
  pointer-events: all;
  background-color: #000;
  transition: all 0.25s ease-in-out;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 500;
  width: 100vw;
}

.lefttransition--enter {
  transform: translateX(-110%);
}
.lefttransition--enteractive {
  transform: translateX(0);
}
.lefttransition--exit {
  transform: translateX(-110%);
}

.righttransition--enter {
  transform: translateX(100%);
}
.righttransition--enteractive {
  transform: translateX(0);
}
.righttransition--exit {
  transform: translateX(100%);
}

.overlaytransition--enter {
  opacity: 0;
}
.overlaytransition--enteractive {
  opacity: 0.6;
}
.overlaytransition--exit {
  opacity: 0;
}