/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.panel-t-o__content, .s-panel, .panel {
  position: relative;
  background: #22222a;
  border-radius: 12px;
}

.panel-t-o__content, .s-panel, .panel__content {
  padding: 12px 24px;
}
@media (min-width: 64em) {
  .panel-t-o__content, .s-panel, .panel__content {
    padding: 24px 30px;
  }
}
@media (min-width: 1200px) {
  .panel-t-o__content, .s-panel, .panel__content {
    padding: 24px 36px;
  }
}

.panel {
  display: flex;
  flex-direction: column;
}
.panel-black-high {
  background-color: #22222a;
}
.panel-black-mid {
  background-color: #22222a;
}
.panel__header--colorful {
  background: linear-gradient(94deg, #f35 0%, #7419ff 100%);
  min-height: 44px;
}
.panel__header--colorful .panel__title {
  border-bottom: none;
}
.panel__title {
  font-weight: 600;
  border-bottom: 1px solid #353540;
  margin-bottom: 12px;
  padding: 12px;
  font-size: 0.875rem;
}
@media (min-width: 64em) {
  .panel__title {
    padding: 12px 30px;
  }
}
@media (min-width: 1200px) {
  .panel__title {
    padding: 12px 36px;
  }
}
.panel__description {
  padding-left: 12px;
  padding-right: 12px;
}
@media (min-width: 64em) {
  .panel__description {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1200px) {
  .panel__description {
    padding-left: 36px;
    padding-right: 36px;
  }
}
.panel__content {
  flex: 1;
}
.panel__footer {
  padding: 24px 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  width: 100%;
  margin: 0 auto;
}
.panel__footer > button,
.panel__footer a,
.panel__footer a:visited {
  margin-bottom: 12px;
  margin-top: 12px;
  width: 100%;
}
.panel__footer > button + button,
.panel__footer > button a,
.panel__footer > button a:visited,
.panel__footer a + button,
.panel__footer a a,
.panel__footer a a:visited,
.panel__footer a:visited + button,
.panel__footer a:visited a,
.panel__footer a:visited a:visited {
  margin-top: 12px;
}
@media (min-width: 64em) {
  .panel__footer {
    justify-content: flex-end;
    flex-direction: row;
    max-width: 100%;
    margin-bottom: 0;
  }
  .panel__footer > button,
.panel__footer a,
.panel__footer a:visited {
    margin-bottom: 0;
    width: auto;
    margin-top: 0;
  }
  .panel__footer > button + button,
.panel__footer > button a,
.panel__footer > button a:visited,
.panel__footer a + button,
.panel__footer a a,
.panel__footer a a:visited,
.panel__footer a:visited + button,
.panel__footer a:visited a,
.panel__footer a:visited a:visited {
    margin-left: 12px;
    margin-top: 0;
  }
}
@media (min-width: 64em) {
  .panel__footer {
    padding: 24px 30px;
  }
}
@media (min-width: 1200px) {
  .panel__footer {
    padding: 24px 36px;
  }
}

.panel-t-o {
  display: flex;
  flex-direction: column;
}
.panel-t-o__title {
  margin-bottom: 12px;
}
.panel-t-o__content {
  flex: 1;
}

.panel-skeletons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-column-gap: 24px;
  margin-bottom: 48px;
  grid-row-gap: 48px;
}