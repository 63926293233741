/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.link__no-decoration:hover {
  text-decoration: none;
}

.notifications-wrapper {
  position: fixed;
  top: 50px;
  right: 0;
  padding: 24px;
  isolation: isolate;
  z-index: 700;
  transition: padding 0.1s ease 1s;
  height: 0;
}
.notifications-wrapper--hidden {
  visibility: hidden;
  opacity: hidden;
}

.notification {
  width: 454px;
  min-height: 88px;
  padding: 16px 44px 16px 20px;
  background: #22222a;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0px 0px 6px #000000c4;
  cursor: pointer;
}
.notification .close {
  position: absolute;
  top: calc(50% - 7px);
  right: 24px;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0;
  cursor: pointer;
}

.achievement {
  display: flex;
  align-items: center;
  border-radius: 12px;
}
.achievement .info {
  background: transparent;
  align-self: flex-start;
}
.achievement .image {
  margin-right: 18px;
  width: 55px;
}
.achievement .achieved {
  color: #15cd72;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
}
.achievement .name {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.achievement .reward {
  font-size: 14px;
  line-height: 1.2em;
  color: #ffa727;
  margin: 0;
}
.achievement .reward svg {
  width: 1.3em;
  height: 1.3em;
  margin-left: 6px;
}

@media (max-width: 768px) {
  .notifications-wrapper {
    min-height: unset;
    width: 100%;
    padding: 0;
    top: 0;
    right: unset;
    z-index: 450;
  }

  .notification {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 20px);
    height: unset;
    min-height: 133px;
    padding: 16px 20px;
  }
  .notification .close {
    top: 24px;
  }

  .achievement {
    align-items: flex-start;
  }
  .achievement .image {
    margin-bottom: auto;
    transform: translateY(22px);
  }
  .achievement .info {
    transform: translateY(20px);
  }
  .achievement .name {
    font-weight: 700;
    text-transform: uppercase;
  }
}
.alert {
  display: flex;
  width: 454px;
  min-height: 88px;
  padding: 16px 44px 16px 20px;
  background: #41414d;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0px 0px 6px #000000c4;
  align-items: start;
  border-radius: 12px;
}
.alert:last-child {
  margin-right: 0;
}
.alert__close {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0;
  cursor: pointer;
}

.rank__logo {
  max-width: 54px;
  max-height: 54px;
  margin-right: 15px;
  font-size: 2.5rem;
}
.rank__information {
  margin-right: 15px;
}
.rank__text {
  margin-bottom: 24px;
  color: #eee;
}
.rank__firstplace {
  color: #f0d341;
}
.rank__secondplace {
  color: #d2d2d2;
}
.rank__thirdplace {
  color: #fbb336;
}
.rank__share {
  color: #eee !important;
  background: #137ffb;
  margin-bottom: 12px;
  padding: 4px 12px;
}
.rank__share:hover {
  background: #72aaec !important;
  border-color: #72aaec !important;
  color: #eee !important;
}
.rank__show {
  color: #eee;
  background-color: transparent;
  padding-top: 4px;
  padding-bottom: 4px;
  text-transform: none;
}