/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.top-bar {
  padding: 36px 12px 0;
  position: relative;
}
@media (min-width: 64em) {
  .top-bar {
    padding: 36px 24px 0;
  }
}
@media (min-width: 1200px) {
  .top-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.top-bar__collection-info {
  display: flex;
  align-items: center;
  padding: 12px 48px 12px 0;
}
.top-bar__change-collection {
  font-weight: 700;
  color: #a5a5b4;
}
.top-bar__logo {
  margin-right: 12px;
  border-radius: 50%;
  border: 3px solid #fefefe;
  height: 56px;
  width: 56px;
  object-fit: cover;
}
.top-bar__collection-name {
  font-size: 1.125rem;
  font-weight: 700;
  color: #fefefe;
}
@media (max-width: 479px) {
  .top-bar__collection-name {
    max-width: 272px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (min-width: 41.87em) {
  .top-bar__collection-name {
    max-width: 100%;
  }
}
.top-bar__nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: 12px;
  margin-bottom: 12px;
  overflow-x: auto;
}
@media (min-width: 1200px) {
  .top-bar__nav {
    overflow-x: unset;
    justify-content: flex-end;
  }
}

.st-navigation {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.st-navigation li {
  margin-bottom: 0;
}
@media (max-width: 1599px) {
  .st-navigation .st-navigation__link {
    padding-left: 12px;
    padding-right: 12px;
  }
}